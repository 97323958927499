<template>
  <div class="w-full">
    <div class="w-100 d-flex search">
      <button class="btn-primary btn-large p-10 mr-1">
        <i class="fas fa-filter mr-3"></i>
        <small>Filtro de búsqueda</small>
      </button>
      <input
        v-model="filterSearch"
        class="ms-auto"
        placeholder="Buscar un usuario en particular"
      />
      <button class="btn-square btn-primary flex-center">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div class="table d-flex w-100 mt-5">
      <div class="tableHeader w-100 d-flex">
        <div
          v-for="(item, index) in dataTable.header"
          v-bind:key="index"
          :class="item.style"
        >
          <h3>{{ item.title }}</h3>
        </div>
      </div>
      <div class="tableRow w-100 d-flex">
        <div class="w-25">
          <small>08/06/2023 10:58 AM</small>
        </div>
        <div class="w-25">
          <small>Miguel Dias</small>
        </div>
        <div class="w-20">
          <div class="flag btn-primary-light"><small>Borrador</small></div>
        </div>
        <div class="w-30">
          <button class="btn-small btn-primary mr-1">
            <small>Gestionar</small>
          </button>
          <button class="btn-square-small btn-gray mr-1">
            <i class="fas fa-eye"></i>
          </button>
          <button class="btn-square-small btn-gray mr-1">
            <i class="fas fa-pen"></i>
          </button>
          <button class="btn-square-small btn-gray mr-1">
            <i class="fas fa-download"></i>
          </button>
        </div>
      </div>
      <div class="tableControls w-100 d-flex p-10">
        <button class="ml-auto mr-4 btn-primary-light btn-square-small">
            <i class="fas fa-angle-double-left"></i>
        </button>
        <button class="mr-4 btn-primary-light btn-square-small">
            <i class="fas fa-angle-left"></i>
        </button>
        <h5 class="mr-4 mt-auto mb-auto">1 de 5</h5>
        <button class="mr-4 btn-primary-light btn-square-small">
            <i class="fas fa-angle-right btn-primary-light btn-square-smal"></i>
        </button>
        <button class="mr-4 btn-primary-light btn-square-small">
            <i class="fas fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// #004884
export default {
  data() {
    return {
      filterSearch: "",
      showTab: false,
      dataTable: {
        header: [
          { title: "Fecha reporte", style: "w-25" },
          { title: "Responsable", style: "w-25" },
          { title: "Estado de gestion", style: "w-20" },
          { title: "Acciones", style: "w-30" },
        ],
        content: [],
      },
    };
  },
  methods: {
    changeShowTab(){
        this.showTab = !this.showTab
    }
  }
};
</script>

<style scoped>
button {
  border-radius: 5px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.p-10 {
  padding: 10px;
}
.d-flex {
  display: flex;
}
.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.ms-auto {
  margin-left: auto;
}
.btn-large {
  min-width: 300px;
}
.btn-small {
  min-width: 90px;
  padding: 3px;
}
.btn-white {
  box-shadow: 0 0 2mm #e7e7e7;
}
.btn-square {
  width: 40px;
  height: 40px;
}
.btn-square-small {
  width: 26px;
  height: 26px;
}
.btn-gray {
  background: #e7e7e7;
  color: black;
}
.btn-primary {
  background: #004884;
  color: white;
}
.btn-warning {
  background: #f8b446;
  color: white;
}
.btn-danger {
  background: #ea4a76;
  color: white;
}
.btn-success {
  background: #309d7e;
  color: white;
}
.btn-warning-light {
  background: #fff3e0;
  color: #f8b446;
}
.btn-danger-light {
  background: #ffedf2;
  color: #ea4a76;
}
.btn-primary-light {
  background: #edf3ff;
  color: #004884;
}
.btn-success-light {
  background: #e5fbf5;
  color: #309d7e;
}
.no-border {
  border: 0px !important;
}
.border-bottom-gray {
  border-bottom: 1px solid #e7e7e7;
}
.flag {
  min-width: 90px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.search > input {
  border: 1px solid;
  border-radius: 10px;
  min-width: 400px;
  margin-right: 10px;
  padding: 0px 10px;
}
.search > button {
  border-radius: 10px;
}
.table {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  flex-wrap: wrap;
}
.tableHeader {
  font-weight: bold;
}
.table > div {
  flex-wrap: wrap;
}
.table > div > div {
  border-bottom: 1px solid black;
  padding: 10px 1px;
}
.progressbar {
  height: 26px;
  border-radius: 5px;
  background: #e5fbf5;
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
}
.progressbar > div {
  height: 26px;
  color: white;
  border-radius: 5px;
  background: #309d7e;
  width: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}
.w-40 {
  width: 40%;
}
.w-35 {
  width: 35%;
}
.w-30 {
  width: 30%;
}
.w-25 {
  width: 25%;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}
.w-10 {
  width: 10%;
}
.w-5 {
  width: 5%;
}
</style>
